import appStrings from '@/app/utility/string.utility';
import PrjMasterProject from '../../../project/projectMaster';
export default {
  name: 'CrmMasterProjectDetails',
  props: {
    crmMasterPrjRowData: Object
  },
  components: {
    PrjMasterProject
  },
  data() {
    return {
      unsubscribe: null,
      showCrmProjectMasterModal: false,
      editMode: false,
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      prjMasterPrj: {
        value: null,
        text: null
      },
      crmMasterPrjCode: null,
      crmMasterPrjName: null,
      crmMasterPrjId: 0,
      tab: null
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.editMode == true) {
          this.addEditCrmMasterProject();
          this.showAlert = true;
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
        if (actionName === 'upload') {
          this.eventBus.$emit('commonUpload', { id: this.crmMasterPrjId });
        }
      }
    });
    if (this.crmMasterPrjRowData !== null) {
      this.crmMasterPrjCode = this.crmMasterPrjRowData.crm_master_project_code;
      this.crmMasterPrjId = this.crmMasterPrjRowData.crm_master_project_id;
      this.crmMasterPrjName = this.crmMasterPrjRowData.crm_master_project_name;
      this.prjMasterPrj = {
        value: this.crmMasterPrjRowData.project_master_project_id,
        text: this.crmMasterPrjRowData.master_project_name
      };
    } else {
      this.editMode = true;
    }
  },
  methods: {
    setChildName(tab) {
      //method for child modal name
      if (tab === 'PrjMasterProject') {
        return (this.childName = 'PRJ Master Project');
      }
    },
    addEditCrmMasterProject() {
      const payload = {
        crm_master_project_code: this.crmMasterPrjCode,
        crm_master_project_id: this.crmMasterPrjId,
        crm_master_project_name: this.crmMasterPrjName,
        project_master_project_id: this.prjMasterPrj.value
      };
      this.loader = true;
      this.$store
        .dispatch('crmInventoryProperty/addEditCrmMasterProject', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.$emit('updateCrmMasterProj');
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    hideCrmProjectMasterModal(flag, component) {
      this.showCrmProjectMasterModal = flag;
      this.tab = component;
      this.setChildName(this.tab);
    },
    prjMasterProjectDetails(item) {
      this.showCrmProjectMasterModal = false;
      this.prjMasterPrj = {
        value: item.master_project_id,
        text: item.master_project_name
      };
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
