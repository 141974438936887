import commonHelper from '@/app/utility/common.helper.utility';
import CrmMasterProjectDetails from './crmMasterProjectDetails';
import PrjMasterProject from '../../project/projectMaster';
export default {
  name: 'CrmMasterProjects',
  components: {
    CrmMasterProjectDetails,
    PrjMasterProject
  },
  props: {
    showCrmSectorModal: Boolean,
    showCrmMasterProjComp: Boolean,
    prjMasterProjName: String,
    prjMasterProjId: Number
  },
  watch: {
    currentPage: function() {
      this.getCrmMasterProjectList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getCrmMasterProjectList();
    }
  },
  data() {
    return {
      unsubscribe: null,
      showCrmProjectMasterModal: false,
      loader: false,
      payload: {},
      editMode: false,
      showAlert: false,
      currentPage: 1,
      totalRows: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      crmMasterPrjCode: null,
      crmMasterPrjName: null,
      prjMasterPrj: {
        value: null,
        text: null
      },
      tab: null,
      crmMasterPrjRowData: null,
      crmMasterPrjData: [],
      crmMasterPrjFields: [
        {
          key: 'master_project_name',
          label: 'PRJ Master Project'
        },
        {
          key: 'project_master_project_id',
          class: 'd-none'
        },
        {
          key: 'crm_master_project_id',
          class: 'd-none'
        },
        {
          key: 'crm_master_project_code',
          label: 'Master Project Code'
        },
        {
          key: 'crm_master_project_name',
          label: 'Master Project Name'
        }
      ]
    };
  },
  mounted() {
    if (this.showCrmSectorModal) {
      this.prjMasterPrj.text = this.prjMasterProjName;
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.hideCrmProjectMasterModal(true, 'CrmMasterProjectDetails');
          this.crmMasterPrjRowData = null;
        }
        if (actionName === 'download' && !this.showCrmProjectMasterModal) {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'crmInventoryProperty/getCrmMasterProjectList',
            'crm-masterproject',
            () => (this.loader = false)
          );
        }
      }
    });
  },
  methods: {
    setChildName(tab) {
      //method for child modal name
      if (tab === 'CrmMasterProjectDetails') {
        return (this.childName = 'CRM Master Project Details');
      } else if (tab === 'PrjMasterProject') {
        return (this.childName = 'PRJ Master Project');
      }
    },
    hideCrmProjectMasterModal(flag, component) {
      this.showCrmProjectMasterModal = flag;
      this.tab = component;
      this.setChildName(this.tab);
    },
    getCrmMasterProjectList() {
      this.payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        master_project_id: this.prjMasterProjId
          ? this.prjMasterProjId
          : this.prjMasterPrj.value,
        crm_master_project_code: this.crmMasterPrjCode,
        crm_master_project_name: this.crmMasterPrjName
      };
      this.loader = true;
      this.$store
        .dispatch('crmInventoryProperty/getCrmMasterProjectList', this.payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.crmMasterPrjData = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    rowSelected(rowData) {
      this.crmMasterPrjRowData = rowData;
      this.$emit('selectedCrmMasterProj', this.crmMasterPrjRowData);
      this.hideCrmProjectMasterModal(true, 'CrmMasterProjectDetails');
    },
    updateCrmMasterProj() {
      this.getCrmMasterProjectList();
    },
    prjMasterProjectDetails(item) {
      this.showCrmProjectMasterModal = false;
      this.prjMasterPrj = {
        value: item.master_project_id,
        text: item.master_project_name
      };
    },
    resetSearchFilters() {
      this.prjMasterPrj = {
        value: null,
        text: null
      };
      this.crmMasterPrjCode = '';
      this.crmMasterPrjName = '';
      this.totalRows = null;
      this.currentPage = 1;
      this.crmMasterPrjData = [];
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
